import styled from 'styled-components';

export const LeadWrapper = styled.div`
  h1,
  h2 {
    text-decoration: none;
  }
  h1 {
    color: #2cabde;
    font-size: 1.5rem;
  }
  h2 {
    color: #42413d;
    font-size: 1rem;
  }
  p {
    color: #42413d;
  }
  .highLight {
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  a {
    &:hover {
      text-decoration: none;
      .highLight {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      }
    }
  }
`;

export const PriceTab = styled.div`
  background-color: #2cabde;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 30px;

  padding: 2px 20px;
  p {
    color: #fff;
    font-weight: bold;
  }
`;
