import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 30px;
  h2 {
    font-size: 1.2rem;
  }
`;
