import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import StickyBox from 'react-sticky-box';
import FilterSideBar from 'components/filtersidebar';
import LatestLeadBox from 'components/latestleadbox';
import LeadBox from 'components/leadbox';
import FeeTable from 'components/FeeTable';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      filterAll: true,
      color: {
        value: 'all',
      },
      fuel: {
        value: 'all',
      },
      price: {
        value: 0,
      },
      mileage: {
        value: 0,
      },
      year: 0,
      seller: {
        value: 'all',
      },
    };
  }

  handleChangeMake(e) {
    var filterAll = false;
    if (e == null || e.length == 0) {
      filterAll = true;
    }
    this.setState({
      filter: e == null ? [] : e,
      filterAll: filterAll,
    });
  }

  singleSelectChange(e) {
    this.setState({
      [e.name]: e,
    });
  }

  show(data) {
    if (
      this.state.filterAll ||
      this.makeSelected(data.node.relationships.field_make.drupal_internal__tid)
    ) {
      if (
        this.state.seller.value != 'all' &&
        this.state.seller.value !=
          data.node.relationships.uid.relationships.roles[0].drupal_internal__id
      ) {
        return false;
      }
      if (
        this.state.color.value != 'all' &&
        this.state.color.value != data.node.field_colour
      ) {
        return false;
      }
      if (
        this.state.fuel.value != 'all' &&
        this.state.fuel.value != data.node.field_fuel
      ) {
        return false;
      }
      if (
        this.state.price.value != 0 &&
        this.state.price.value < data.node.field_guide_price
      ) {
        return false;
      }
      if (
        this.state.mileage.value != 0 &&
        this.state.mileage.value < data.node.field_mileage
      ) {
        return false;
      }
      if (
        this.state.year.value != 0 &&
        this.state.year.value < data.node.field_year_of_manufacture
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  makeSelected(tid) {
    var show = false;
    this.state.filter.map(item => {
      if (item.value == tid) {
        show = true;
        return false;
      }
    });
    return show;
  }

  render() {
    const { data } = this.props;
    const allLeads = this.props.data.allLeads;
    const latestLead = this.props.data.latestLead.edges[0];
    return (
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12 col-lg-4 mb-4 mb-lg-0">
              <StickyBox
                style={{ zIndex: '90' }}
                offsetTop={50}
                offsetBottom={150}
              >
                <div className="sidebar">
                  <FilterSideBar
                    data={data}
                    state={this.state}
                    handleChangeMake={this.handleChangeMake.bind(this)}
                    singleSelectChange={this.singleSelectChange.bind(this)}
                  />
                  <div className="d-none d-lg-block">
                    <FeeTable />
                  </div>
                </div>
              </StickyBox>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row">
                {this.show(latestLead) && <LatestLeadBox data={latestLead} />}
                {allLeads.edges.map(edge => (
                  <React.Fragment key={edge.node.id}>
                    {this.show(edge) && <LeadBox data={edge} />}
                  </React.Fragment>
                ))}
                <div className="d-block d-lg-none col-12">
                  <FeeTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    latestLead: allNodeLead(
      filter: { status: { eq: true } }
      sort: { fields: [created], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
          drupal_id
          created
          title
          field_registration_plate
          field_model
          field_year_of_manufacture
          field_mileage
          field_colour
          field_fuel
          field_gearbox
          field_guide_price
          drupal_internal__nid
          relationships {
            uid {
              id
              relationships {
                roles {
                  id
                  drupal_internal__id
                }
              }
            }
            field_front_driver_side {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            field_make {
              drupal_internal__tid
              name
            }
          }
        }
      }
    }
    allLeads: allNodeLead(
      filter: { status: { eq: true } }
      sort: { fields: [created], order: DESC }
      skip: 1
    ) {
      edges {
        node {
          id
          drupal_id
          created
          title
          field_registration_plate
          field_model
          field_year_of_manufacture
          field_mileage
          field_colour
          field_fuel
          field_gearbox
          field_guide_price
          drupal_internal__nid
          relationships {
            uid {
              id
              relationships {
                roles {
                  id
                  drupal_internal__id
                }
              }
            }
            field_front_driver_side {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            field_make {
              drupal_internal__tid
              name
            }
          }
        }
      }
    }
    allTaxonomyTermMake {
      totalCount
      edges {
        node {
          relationships {
            node__lead {
              status
              drupal_internal__nid
            }
          }
          drupal_internal__tid
          name
        }
      }
    }
  }
`;
