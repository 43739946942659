import styled from 'styled-components';
import { Link } from 'gatsby';
//import MEDIA from 'helpers/mediaTemplates';

export const Header = styled.div`
  display: block;
  background-color: #2cabde;
  color: #fff;
  padding: 15px 15px;
  h1 {
    font-size: 1rem;
    margin: 0;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
`;

export const Footer = styled.div`
  background-color: #2cabde;
  padding: 20px 15px;
  .center {
    font-size: 0.9rem;
    font-weight: normal !important;
    text-transform: capitalize;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
`;

export const LinkWrapper = styled(Link)`
  &:hover {
    text-decoration: none !important;
    .highLight {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const HighLight = styled.div`
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
`;
