import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { Wrapper } from './filtersidebar.css';

const selectbox = {
  width: '100%',
};

class FilterSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.state.color.value,
      fuel: this.props.state.fuel.value,
      price: this.props.state.price.value,
      mileage: this.props.state.mileage.value,
      year: this.props.state.year.value,
      data: this.props.data,
      seller: this.props.state.seller.value,
      value: 'all',
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.state.color.value != this.props.state.color.value) {
      this.setState({
        color: this.props.state.color,
      });
    }
    if (prevProps.state.fuel.value != this.props.state.fuel.value) {
      this.setState({
        fuel: this.props.state.fuel,
      });
    }
    if (prevProps.state.price.value != this.props.state.price.value) {
      this.setState({
        price: this.props.state.price,
      });
    }
    if (prevProps.state.mileage.value != this.props.state.mileage.value) {
      this.setState({
        mileage: this.props.state.mileage,
      });
    }
    if (prevProps.state.year.value != this.props.state.year.value) {
      this.setState({
        year: this.props.state.year,
      });
    }
    if (prevProps.state.seller.value != this.props.state.seller.value) {
      this.setState({
        seller: this.props.state.seller,
      });
    }
    return null;
  }

  render() {
    const { data, color, fuel, price, mileage, year, seller } = this.state;
    const CarmakeOptions = new Array();
    {
      data.allTaxonomyTermMake.edges.map(edge => {
        var count = 0;
        var include = false;
        if (edge.node.relationships.node__lead != null) {
          edge.node.relationships.node__lead.map(item => {
            if (item.drupal_internal__nid && item.status == true) {
              count++;
              include = true;
            }
          });
          if (include) {
            CarmakeOptions[edge.node.drupal_internal__tid] = {
              value: edge.node.drupal_internal__tid,
              label: edge.node.name + ' (' + count + ')',
            };
          }
        }
      });
    }
    const ColorOptions = [
      { value: 'all', label: 'All Colours', name: 'color' },
      { value: 'BLACK', label: 'Black', name: 'color' },
      { value: 'RED', label: 'Red', name: 'color' },
    ];
    const FuelOptions = [
      { value: 'all', label: 'All Fuel Types', name: 'fuel' },
      { value: 'DIESEL', label: 'Diesel', name: 'fuel' },
      { value: 'PETROL', label: 'Petrol', name: 'fuel' },
      { value: 'HYBRID ELECTRIC', label: 'Hyrid Electric', name: 'fuel' },
      { value: 'ELECTRICITY', label: 'EV', name: 'fuel' },
    ];
    const sellerOptions = [
      { value: 'all', label: 'All', name: 'seller' },
      { value: 'customer', label: 'Private Seller', name: 'seller' },
      { value: 'dealer', label: 'Trade Seller', name: 'seller' },
    ];
    const PriceOptions = [
      { value: '0', label: 'No Price Limit', name: 'price' },
      { value: '200000', label: 'up to £200,000', name: 'price' },
      { value: '150000', label: 'up to £150,000', name: 'price' },
      { value: '100000', label: 'up to £100,000', name: 'price' },
      { value: '80000', label: 'up to £80,000', name: 'price' },
      { value: '60000', label: 'up to £60,000', name: 'price' },
      { value: '40000', label: 'up to £40,000', name: 'price' },
      { value: '20000', label: 'up to £20,000', name: 'price' },
      { value: '10000', label: 'up to £10,000', name: 'price' },
    ];
    const MileageOptions = [
      { value: '0', label: 'No Mileage Limit', name: 'mileage' },
      { value: '60000', label: 'up to 60,000', name: 'mileage' },
      { value: '40000', label: 'up to 40,000', name: 'mileage' },
      { value: '20000', label: 'up to 20,000', name: 'mileage' },
      { value: '10000', label: 'up to 10,000', name: 'mileage' },
      { value: '5000', label: 'up to 5000', name: 'mileage' },
      { value: '2000', label: 'up to 2000', name: 'mileage' },
    ];
    const AgeOptions = [{ value: '0', label: 'Any Age', name: 'year' }];
    for (let i = 2019; i >= 2000; i--) {
      AgeOptions.push({ value: i, label: 'up to ' + i, name: 'year' });
    }
    return (
      <Wrapper>
        <h2 className="text-center mb-4">Filter results</h2>
        <Select
          className="mb-2"
          placeholder="Select car makes"
          style={selectbox}
          onChange={this.props.handleChangeMake}
          options={CarmakeOptions}
          isMulti
        />
        <Select
          className="mb-2"
          placeholder="Select fuel type"
          style={selectbox}
          value={fuel}
          options={FuelOptions}
          onChange={this.props.singleSelectChange}
        />
        <Select
          className="mb-2"
          placeholder="Mileage range"
          style={selectbox}
          value={mileage}
          options={MileageOptions}
          onChange={this.props.singleSelectChange}
        />
        <Select
          className="mb-2"
          placeholder="Year"
          style={selectbox}
          value={year}
          options={AgeOptions}
          onChange={this.props.singleSelectChange}
        />
        <Select
          className="mb-2"
          placeholder="Trade or private seller"
          style={selectbox}
          value={seller}
          options={sellerOptions}
          onChange={this.props.singleSelectChange}
        />
      </Wrapper>
    );
  }
}

FilterSideBar.propTypes = {
  data: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  handleChangeMake: PropTypes.func,
  singleSelectChange: PropTypes.func,
};

export default FilterSideBar;
