import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { LeadWrapper, PriceTab } from './leadbox.css';

const LeadBox = ({ data }) => {
  return (
    <LeadWrapper className="col-12 col-md-6 mb-4">
      <Link to={data.node.drupal_id}>
        <div className="highLight">
          {data.node.field_guide_price > 0 && (
            <PriceTab>
              <p className="m-0">£{data.node.field_guide_price}</p>
            </PriceTab>
          )}

          {data.node.relationships.field_front_driver_side.localFile && (
            <Img
              fluid={
                data.node.relationships.field_front_driver_side.localFile
                  .childImageSharp.fluid
              }
            />
          )}
          <div className="bg-white p-3">
            <h1 className="text-capitalize">
              {data.node.relationships.field_make.name.toLowerCase()}
            </h1>
            <h2>{data.node.field_model}</h2>
            <div className="mt-4">
              <div className="d-flex no-gutters border-bottom mb-1 pb-1 pt-1">
                <div className="col">
                  <p className="font-weight-bold mb-0">Year</p>
                </div>
                <div className="col text-right">
                  <p className="mb-0">{data.node.field_year_of_manufacture}</p>
                </div>
              </div>
              <div className="d-flex no-gutters border-bottom mb-1 pb-1 pt-1">
                <div className="col">
                  <p className="font-weight-bold mb-0">Mileage</p>
                </div>
                <div className="col text-right">
                  <p className="mb-0">{data.node.field_mileage}</p>
                </div>
              </div>
              <div className="d-flex no-gutters border-bottom mb-1 pb-1 pt-1">
                <div className="col">
                  <p className="font-weight-bold mb-0">Fuel</p>
                </div>
                <div className="col text-right">
                  <p className="mb-0 text-capitalize">
                    {data.node.field_fuel.toLowerCase()}
                  </p>
                </div>
              </div>
              <div className="d-flex no-gutters pt-1">
                <div className="col">
                  <p className="font-weight-bold mb-0">Gearbox</p>
                </div>
                <div className="col text-right">
                  <p className="mb-0 text-capitalize">
                    {data.node.field_gearbox.toLowerCase()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </LeadWrapper>
  );
};

LeadBox.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LeadBox;
