import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Header, Footer, LinkWrapper, HighLight } from './latestleadbox.css';

const LatestLeadBox = ({ data }) => {
  return (
    <div className="col-12 mb-4 lead-item-latest">
      <LinkWrapper to={data.node.drupal_id}>
        <HighLight className="highLight">
          <Header>
            <h1>CAR NEW IN</h1>
            <h2>
              {data.node.relationships.field_make.name} {data.node.field_model}
            </h2>
          </Header>
          {data.node.relationships.field_front_driver_side.localFile !=
            null && (
            <Img
              alt={data.node.title}
              fluid={
                data.node.relationships.field_front_driver_side.localFile
                  .childImageSharp.fluid
              }
            />
          )}
          <Footer>
            <div className="row no-gutters justify-content-between align-items-center">
              <div className="col text-center text-white font-weight-bold">
                <p className="mb-0">{data.node.field_year_of_manufacture}</p>
              </div>
              <div className="col text-center text-white font-weight-bold center">
                <p className="mb-0">
                  {data.node.field_year_of_manufacture} /{' '}
                  {data.node.field_gearbox.toLowerCase()} /{' '}
                  {data.node.field_fuel.toLowerCase()}
                </p>
              </div>
              <div className="col text-center text-white font-weight-bold">
                <p className="mb-0">{data.node.field_mileage} miles</p>
              </div>
            </div>
          </Footer>
        </HighLight>
      </LinkWrapper>
    </div>
  );
};

LatestLeadBox.propTypes = {
  data: PropTypes.object.isRequired,
};

export default LatestLeadBox;
